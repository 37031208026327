<template>
  <div class="w-full surface-section text-center">
    <VOnboardingWrapper ref="wrapper" :steps="steps" />

    <ConfirmDialog />
    <CardDialog v-if="showCardDialog"
      @onDialogClose="showCardDialog = false, sortApps(), cardToEdit = undefined, editDialog = false"
      :visible="showCardDialog" :edit="editDialog" :card="cardToEdit"></CardDialog>
    <Toolbar class="flex-1 p-0 m-0 border-noround" v-if="checkUserRole('Admin', jupiterClientId) || false">
      <template #start>
        <Button class="p-button-text" label="Create Card" icon="pi pi-plus" @click="showCardDialog = true"></Button>
      </template>
    </Toolbar>
    <Transition class="fadein">
      <div v-if="favorites.length > 0">
        <h2 class="text-3xl mb-4 mt-4 text-700">Favorite Apps</h2>

        <div class="w-full flex justify-content-center">
          <div class="w-full xl:w-9">
            <TransitionGroup class="grid" name="list" tag="Card">
              <div class="col-12 md:col-3" v-bind:key="app.title || ''" v-for="app in favorites">
                <ConPortalCard :key="app.title!" :title="app.title!" :card-icon="app.icon!" :tags="app.tags!"
                  :description="app.description!" :route="app.route!" :isFavorite="app.isFavorite!"
                  @onFavorite="onToggleFavorite(app.id)"
                  :isAllowed="checkUserRole(app.userRole || '', app.clientId || '') || false"
                  :isExternal="app.externalLink!" @onEdit="editCard(app)" @onDelete="confirmDelete(app)"
                  :isAdmin="checkUserRole('Admin', jupiterClientId) || false">
                </ConPortalCard>
              </div>
            </TransitionGroup>
          </div>
        </div>
        <Divider class=" w-auto text-color-secondary" />
      </div>
    </Transition>


    <div>
      <h2 id="welcome" class="text-3xl mb-4 mt-4 text-700">Available Apps</h2>
      <div class="flex justify-content-center flex-wrap">
        <div v-if="allApps.length > 0" class="w-full xl:w-9">
          <TransitionGroup class="grid" name="list" tag="Card">
            <div :id="`app${i}`" class="col-12 md:col-3" v-bind:key="app.title!" v-for="(app, i) in allApps">
              <ConPortalCard :isFavorite="app.isFavorite || false" :key="app.id" :title="app.title!"
                :card-icon="app.icon!" :tags="app.tags!" :description="app.description!" :route="app.route!"
                :isAllowed="checkUserRole(app.userRole || '', app.clientId || '') || false"
                :isExternal="app.externalLink!" @onFavorite="onToggleFavorite(app.id)" @onEdit="editCard(app)"
                @onDelete="confirmDelete(app)" :isAdmin="checkUserRole('Admin', jupiterClientId) || false">
              </ConPortalCard>
            </div>
          </TransitionGroup>
        </div>
        <div v-else>
          <h3 v-if="!isLoading">There's nothing left to favorite :(</h3>
          <h3 v-else>Loading...</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import { onKeycloakToken, type KeycloakService } from '@congatec/authentication-lib';
import { onMounted, ref, watch } from 'vue';
import { useCardStore } from "../../../stores/cardStore";
import { ConPortalCard } from '@congatec/primevue-components-lib';
import Divider from 'primevue/divider';
import { useToast } from 'primevue/usetoast';
import { CardFlag, CardsService, TourTextService, type CardsListResponse_Card as CardType } from '@/apis/jupiter-api';
import CardDialog from '@/jupiter/components/shared/CardDialog.vue';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import { useKeycloak } from '@congatec/authentication-lib';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'

import 'v-onboarding/dist/style.css'


const wrapper = ref(null)

const { start, goToStep, finish } = useVOnboarding(wrapper)

const steps = ref<any[]>([]);


const cardStore = useCardStore();
const toast = useToast();
const showCardDialog = ref(false);
const editDialog = ref(false);
const confirm = useConfirm();
const jupiterClientId = import.meta.env.VITE_KEYCLOAK_CLIENTID;

const maxFavorites = 20;
const isLoading = ref(true);
const favorites = ref<CardType[]>([]);
const allApps = ref<CardType[]>([]);
const keycloak = useKeycloak()
const cardToEdit = ref<CardType | undefined>();



onMounted(() => {
});

const LOCALSTORAGE_TOUR_SEEN = "CONGATEC_JUPITER_TOUR_SEEN";

const startTour = async () => {
  if (localStorage.getItem(LOCALSTORAGE_TOUR_SEEN)) {
    return;
  }
  localStorage.setItem(LOCALSTORAGE_TOUR_SEEN, "1");

  const allSteps = await TourTextService.getApiV1TourTourText();

    // nasty quick hack to get an id into the first fav button :^)
   document.getElementsByClassName('p-button p-component p-button-icon-only p-button-rounded p-button-text h-auto w-auto')[0].id = "favorites0";

  steps.value = [];
  for (const nextStep in allSteps.steps!) {
    const next = allSteps.steps![nextStep];
    let element: string | undefined = nextStep;

    steps.value.push(
      { attachTo: { element }, content: { title: next.title!, description: next.description! } })
  }

  start();
}

const endTour = () => {
  finish();
}


const sortApps = async () => {
  cardStore.sortCards();
  allApps.value = cardStore.cards.filter((x: CardType) => x.flag == CardFlag.APP_CARD)
  favorites.value = allApps.value.filter((x: CardType) => x.isFavorite);
  allApps.value = allApps.value.filter((x: CardType) => !x.isFavorite);
}

onKeycloakToken(async (authenticationService: KeycloakService) => {
  sortApps();
  startTour();
});

const onToggleFavorite = async (appId: number | undefined) => {
  if (favorites.value.length >= maxFavorites && appId != undefined) {
    toast.add({
      severity: "info",
      summary: `Only ${maxFavorites} favorites allowed.`,
      detail: "",
      life: 3000,
    });
    return;
  }
  await CardsService.putApiV1CardsFavoritesToggle(appId);
  cardStore.toggleFavorite(appId!);
  sortApps();
  isLoading.value = false;
}

const checkUserRole = (userRole: string | undefined, clientId: string | undefined) => {
  return keycloak?.hasRoles([userRole || ''], clientId || '');
}

const editCard = (card: CardType) => {
  editDialog.value = true;
  cardToEdit.value = card;
  showCardDialog.value = true;
}

const confirmDelete = (card: CardType) => {
  confirm.require({
    header: 'Are you sure?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: `This will delete ${card.title}`,
    accept: async () => {
      try {
        let cards = (await CardsService.deleteApiV1Cards(card.id)).cards;
        if (cards) {
          cardStore.clear();
          cardStore.cards = cards
          sortApps()
        }
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Template deleted', life: 3000 });
      }
      catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the Template: ' + ex.message, life: 3000 });
      }
    },
    reject: async () => {
      // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  })
}
</script>

<style>
.v-onboarding-item {
  background-color: var(--surface-50)
}

.v-onboarding-item__actions button.v-onboarding-btn-primary:hover,
.v-onboarding-item__actions button.v-onboarding-btn-secondary:hover {
  background-color: var(--primary-400)
}

.v-onboarding-item__actions button.v-onboarding-btn-primary,
.v-onboarding-item__actions button.v-onboarding-btn-secondary {
  background-color: var(--primary-color)
}

.v-onboarding-item__description,
.v-onboarding-item__header,
.v-onboarding-item__actions button.v-onboarding-btn-secondary {
  color: var(--surface-900)
}

.v-onboarding-item__actions button.v-onboarding-btn-secondary {
  border-color: transparent;
}

.v-onboarding-item__header-close {
  background-color: transparent !important;
  color: var(--primary-color);
  border-color: transparent !important;
  font-family: 'primeicons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  width: 4rem;
}

</style>

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardsCreateRequest } from '../models/CardsCreateRequest';
import type { CardsCreateResponse } from '../models/CardsCreateResponse';
import type { CardsDeleteResponse } from '../models/CardsDeleteResponse';
import type { CardsListResponse } from '../models/CardsListResponse';
import type { CardsToggleFavoriteResponse } from '../models/CardsToggleFavoriteResponse';
import type { CardsUpdateRequest } from '../models/CardsUpdateRequest';
import type { CardsUpdateResponse } from '../models/CardsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CardsService {

    /**
     * @param requestBody 
     * @returns CardsCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1Cards(
requestBody?: CardsCreateRequest,
): CancelablePromise<CardsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CardsDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1Cards(
id?: number,
): CancelablePromise<CardsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/cards',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns CardsListResponse OK
     * @throws ApiError
     */
    public static getApiV1Cards(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<CardsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/cards',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CardsUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1Cards(
requestBody?: CardsUpdateRequest,
): CancelablePromise<CardsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/cards',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cardId 
     * @returns CardsToggleFavoriteResponse OK
     * @throws ApiError
     */
    public static putApiV1CardsFavoritesToggle(
cardId?: number,
): CancelablePromise<CardsToggleFavoriteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/cards/favorites/toggle',
            query: {
                'CardId': cardId,
            },
        });
    }

}
